import { Form, RangePicker } from "@ster/ster-toolkit";
// eslint-disable-next-line import/no-extraneous-dependencies
import { RangeValue } from "rc-picker/lib/interface";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { constSelector, useRecoilValue } from "recoil";

import { RangePickerBaseProps } from "antd/es/date-picker/generatePicker";
import {
  add,
  differenceInDays,
  endOfDay,
  isSameMonth,
  isWithinInterval,
  startOfDay,
} from "date-fns";
import { SterWebsiteModelPortalSalesPeriod } from "../api";
import { packagesState, salesPeriodsState } from "../atoms";
import { Medium } from "../types";

type PeriodInputPickerProps = RangePickerBaseProps<Date> & {
  inBetween?: [Date, Date];
};

const PeriodInputPicker = ({
  allowClear,
  inBetween,
  onChange,
  format,
  value: defaultValue,
}: PeriodInputPickerProps) => {
  const [dates, setDates] = useState<RangeValue<Date> | []>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [hackValue, setHackValue] = useState<any>();
  const [value, setValue] = useState<unknown>();

  useEffect(() => {
    setDates(defaultValue ?? []);
    setValue(defaultValue);
  }, [defaultValue]);

  const isDisabledDate = useCallback(
    (current: Date) => {
      if (inBetween) {
        return !isWithinInterval(current, {
          start: inBetween[0],
          end: inBetween[1],
        });
      }

      if (dates?.[0]) {
        return !isSameMonth(current, dates[0]);
      }

      if (dates?.[1]) {
        return !isSameMonth(current, dates[1]);
      }

      return differenceInDays(current, new Date()) === 0;
    },
    [dates, inBetween],
  );

  const onOpenChange = useCallback((open: boolean) => {
    if (open) {
      // Hack volgens AntD voorbeeld om geselecteerde waarde te resetten bij openen
      setHackValue([]);
      setDates([]);
    } else {
      setHackValue(undefined);
    }
  }, []);

  const setNewValue = useCallback(
    (newValue: RangeValue<Date>, formatString: [string, string]) => {
      setValue(newValue);
      // onChange in een setTimeout ivm een bug waarbij de periode leeg is bij het wisselen naar een maand waarvoor de pakketten opgehaald moeten worden
      setTimeout(() => onChange?.(newValue, formatString));
    },
    [onChange],
  );

  return (
    <RangePicker
      value={hackValue || value}
      disabledDate={isDisabledDate}
      format={format}
      allowClear={allowClear}
      onOpenChange={onOpenChange}
      onCalendarChange={(val) => setDates(val)}
      onChange={setNewValue}
    />
  );
};
const emptyPeriods: SterWebsiteModelPortalSalesPeriod[] = [];

const PeriodInput = ({
  medium,
  period,
  packageCode,
  allowClear,
  inBetween,
  useSalesPeriod,
}: {
  medium: Medium;
  period?: [Date, Date];
  inBetween?: [Date, Date];
  packageCode?: string;
  allowClear: boolean;
  useSalesPeriod?: boolean;
}) => {
  const startDate = period?.[0];
  const packages = useRecoilValue(
    startDate
      ? packagesState({
          medium,
          monthNumber: startDate.getMonth() + 1,
          year: startDate.getFullYear(),
        })
      : constSelector(undefined),
  )?.packages;

  const salesPeriods = useRecoilValue(salesPeriodsState);
  const betweenSalesPeriods = useMemo<[Date, Date] | undefined>(() => {
    const current =
      salesPeriods.find((s) => s.medium === medium)?.result ?? emptyPeriods;
    if (current.length === 0) {
      return undefined;
    }

    return [
      endOfDay(
        add(current[0].salesPeriodStartDate ?? new Date(), { days: -1 }),
      ),
      startOfDay(
        add(current[current.length - 1].salesPeriodEndDate ?? new Date(), {
          days: 1,
        }),
      ),
    ];
  }, [medium, salesPeriods]);

  const selectedPackage = useMemo(
    () =>
      packages?.find((p) =>
        p.targetGroups.some((s) => s.packageCode === packageCode),
      ),
    [packageCode, packages],
  );

  const validatePeriod = useCallback((_: unknown, dates: Date[]) => {
    if (!dates || dates.length === 0 || !isSameMonth(dates[0], dates[1])) {
      return Promise.reject();
    }

    return Promise.resolve();
  }, []);

  const validateMinDays = useCallback(
    (_: unknown, dates: Date[]) => {
      if (
        selectedPackage &&
        Number(selectedPackage.minDays) > 0 &&
        differenceInDays(endOfDay(dates[1]), endOfDay(dates[0])) + 1 <
          Number(selectedPackage.minDays)
      ) {
        return Promise.reject(
          new Error(
            `Periode moet minimaal ${Number(
              selectedPackage.minDays,
            )} dagen zijn`,
          ),
        );
      }

      return Promise.resolve();
    },
    [selectedPackage],
  );

  return (
    <Form.Item
      name="period"
      validateFirst
      rules={[
        {
          required: true,
          message: "Kies een periode binnen 1 maand",
          validator: validatePeriod,
        },
        {
          validator: validateMinDays,
        },
      ]}
      noStyle
      dependencies={["package"]}
    >
      <PeriodInputPicker
        inBetween={
          useSalesPeriod && betweenSalesPeriods
            ? betweenSalesPeriods
            : inBetween
        }
        format="dd MMM yyyy"
        allowClear={allowClear}
        picker="month"
      />
    </Form.Item>
  );
};

export default memo(PeriodInput);
