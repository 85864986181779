import { Icons } from "@ster/ster-toolkit";
import { memo, useCallback } from "react";

import { SterWebsiteModelPortalForecast } from "../../api";
import styles from "./Forecast.module.less";

export interface ForecastRowProps {
  forecast: SterWebsiteModelPortalForecast;
  canDelete?: boolean;
  onDeleteConversionGroup?: (value: string) => void;
}

const ForecastRow = ({
  forecast,
  canDelete,
  onDeleteConversionGroup,
}: ForecastRowProps): JSX.Element => {
  const handleDeleteConversionGroup = useCallback(() => {
    if (onDeleteConversionGroup) {
      onDeleteConversionGroup(forecast.targetGroup ?? "");
    }
  }, [forecast.targetGroup, onDeleteConversionGroup]);

  return (
    <div className={styles.forecastRow}>
      <div className={styles.forecastCol}>
        <span className={styles.forecastCol__value}>
          {forecast.targetGroup}
        </span>
      </div>
      <div className={styles.forecastCol}>
        <span className={styles.forecastCol__value}>
          {forecast.bereikPercentage}
        </span>
      </div>
      <div className={styles.forecastCol}>
        <span className={styles.forecastCol__value}>
          {Intl.NumberFormat("nl").format(forecast.bereikNumber ?? 0)}
        </span>
      </div>
      <div className={styles.forecastCol}>
        <span className={styles.forecastCol__value}>{forecast.gcf}X</span>
      </div>
      {canDelete && (
        <div className={`${styles.forecastCol} ${styles.forecastCol__delete}`}>
          <span className={styles.forecastCol__value}>
            <span
              role="button"
              onClick={handleDeleteConversionGroup}
              tabIndex={0}
              aria-label="Verwijder doelgroep"
              title="Verwijder doelgroep"
            >
              <Icons.DeleteIcon fill="#fff" />
            </span>
          </span>
        </div>
      )}
    </div>
  );
};

export default memo(ForecastRow);
