import { Button, Icons } from "@ster/ster-toolkit";
import { Dropdown, Form, FormInstance } from "antd";
import { memo } from "react";

import { ItemType } from "antd/es/menu/hooks/useItems";
import { SterWebsiteModelPortalPackageExtended } from "../../api";
import styles from "./PackageAdd.module.less";

const PackageAdd = ({
  form,
  packages,
  onAdd,
}: {
  form: FormInstance;
  packages: SterWebsiteModelPortalPackageExtended[];
  onAdd: (packageId: number) => void;
}) => (
  <Form form={form} layout="inline" className={styles.packageAddForm}>
    {packages.length > 1 ? (
      <Dropdown
        menu={{
          items: packages.map(
            (p) =>
              ({
                label: p.name,
                key: p.id,
                className: "more-menu-item",
                onClick: () => onAdd(p.id),
              }) as ItemType,
          ),
        }}
        trigger={["click"]}
        overlayClassName="more-menu"
      >
        <span className="more-menu-button-wrapper">
          <Button mode="secondary" className="more-menu-button">
            <span className="more-menu-button__label">
              <span>+ Pakket toevoegen</span>
            </span>
            <Icons.ChevronDownIcon fill="#fff" />
          </Button>
        </span>
      </Dropdown>
    ) : (
      <Button
        mode="outline"
        className="more-menu-button"
        onClick={() => onAdd(packages[0].id)}
      >
        <span className="more-menu-button__label">
          <span>+ {packages[0].name} pakket toevoegen</span>
        </span>
      </Button>
    )}
  </Form>
);

export default memo(PackageAdd);
